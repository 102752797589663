import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'event-dash',
    loadChildren: () => import('./pages/zevent-dash/event-dash.module').then( m => m.EventDashPageModule)
  },
  {
    path: 'host/event-start',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/host/event-start/event-start.module').then( m => m.EventStartPageModule)
  },
  {
    path: 'host/play/single',
    loadChildren: () => import('./pages/host/play/single/single.module').then( m => m.SinglePageModule)
  },
  {
    path: 'host/play/multi',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/host/play/multi/multi.module').then( m => m.MultiPageModule)
  },
  {
    path: 'test-pad',
    loadChildren: () => import('./pages/test-pad/test-pad.module').then( m => m.TestPadPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'player',
    loadChildren: () => import('./pages/player/player.module').then( m => m.PlayerPageModule)
  },
  {
    path: 'host/event-history',
    loadChildren: () => import('./pages/host/history/history.module').then( m => m.HistoryPageModule)
  },  {
    path: 'my-account',
    loadChildren: () => import('./my-account/my-account.module').then( m => m.MyAccountPageModule)
  },
  {
    path: 'share-app',
    loadChildren: () => import('./share-app/share-app.module').then( m => m.ShareAppPageModule)
  },








];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
