import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, Platform } from '@ionic/angular';
import { UserService } from './services/user.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private user: UserService,
    private loadController: LoadingController,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
    //  this.loadController.create({message:'...Loading...'}); UNTIL onEvent "initComplete"
      await this.user.initUserData();
        if (this.user.isLoggedIn === false) {
          this.router.navigate(['/home']);
        }
        else {
          this.router.navigate(['/home']);
        }
    });
  }
}
