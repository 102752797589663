import { Injectable } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';

import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Storage } from '@capacitor/storage';
import { finalize, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { doc, getDoc, getDocs } from '@firebase/firestore';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public isLoggedIn = false;
  public eventInProgress = false;

  public userDetails: any = {
    user: {},
  };
  public userDetails$: BehaviorSubject<any> = new BehaviorSubject(null);
  hasNewMatches = false;
  otherUserDetail: any;

  constructor(
    public apollo: Apollo,
    private fb: Firestore,
    private route: Router
  ) {}
  async initUserData() {
    //TODO:  Check to see if the user is still in a game by checking localStorage and getting currentEventID ...  then
    // Check to see the status of that game.  If that game is complete ... then reset currentEventId to null or ''.
    // This will take user to home screen
    //
    // If user is in a game .. then pull the data for the current EventId  this.getCurrentEvent()
    let data: any;
    try {
      data = await Storage.get({ key: 'cotg_userdetails' });
    } catch (e) {
      console.log(e, 'fuc');
    }
    try {
      const resume = await this.resumeEvent();
      console.log(`Event in Progress: ${resume}`);
    } catch (e) {
      console.log(e, 'fuc');
    }
    try {
      const userdata = JSON.parse(data.value);
      if (userdata) {
        this.isLoggedIn = true;
        this.userDetails = userdata;
        console.log(this.userDetails, 'INIT LOADING');

        this.getCurrentUser().subscribe((res) => {
          this.userDetails = res.data;
          this.userDetails$.next(res.data);
          this.pollerUserData();
        });
      } else {
        this.isLoggedIn = false;
        this.finalizeInit();
      }
      finalize(() => this.finalizeInit());
    } catch (e) {
      this.finalizeInit();
    }
  }
  finalizeInit() {
    const initComplete = new Event('initComplete');
    window.dispatchEvent(initComplete);
  }
  getCurrentUser() {
    const userId: string = this.userDetails.user.id;
    const variables = {
      userId: parseFloat(userId),
    };
    return this.apollo.watchQuery({
      query: gql`
        query ($userId: Float!) {
          user(id: $userId) {
            id
            firstName
            lastName
            city
            state
            country
            email
            mobile
          }
        }
      `,
      variables,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
  pollerUserData() {
    setInterval(() => {
      this.getCurrentUser().subscribe((res) => {
        this.userDetails$.next(res.data);
      });
    }, 5000);
  }
  register(data) {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($data: UserInput!) {
            createUser(data: $data) {
              id
              email
            }
          }
        `,
        variables: { data },
      })
      .pipe(map((res: any) => res.data.createUser));
  }
  emailExists(email) {
    const variables = {
      email,
    };
    return this.apollo.query({
      query: gql`
        query ($email: String!) {
          emailExists(email: $email)
        }
      `,
      variables,
      fetchPolicy: 'network-only',
    });
  }
  userNameExists(userName) {
    const variables = {
      userName,
    };
    return this.apollo.query({
      query: gql`
        query ($userName: String!) {
          userNameExists(userName: $userName)
        }
      `,
      variables,
      fetchPolicy: 'network-only',
    });
  }
  async updateUser(id: any, data: any){
    const userId: string = this.userDetails.user.id;
    const variables = {
      id: parseFloat(id),
      data
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation updateUser ($id: Float! , $data: UserInput!) {
          updateUser(id: $id, data: $data) {
            id
            firstName
            lastName
            city
            state
            country
            email
            mobile
          }
        }
      `,
      variables,
      fetchPolicy: 'network-only',
    });
  }
  public signIn(data) {
    const variables = {
      ...data,
    };
    return this.apollo.watchQuery({
      query: gql`
        query ($password: String, $email: String!) {
          userLogin(password: $password, email: $email) {
            status
            token
            user {
              id
              email
            }
          }
        }
      `,
      variables,
    }).valueChanges;
  }
  public async signOut(){
    //Remove the Storage
   await Storage.remove({key:'cotg_userdetails'});
    this.userDetails = {};
    this.userDetails$.next({});
    this.isLoggedIn = false;
    this.hasNewMatches = false;
  }
  async getStorageEvent(): Promise<any> {
    const res = await Storage.get({ key: 'cotg_currentEventFirebaseId' });
    if (!res || res.value === null) {
      Promise.resolve(null);
      return null;
    }
    //Get Event From Firebase
    const eventElem = await getDoc(doc(this.fb, 'events', res.value));
    const id = eventElem.id;
    const eventData = eventElem.data();
    const data = { ...eventData, id };
    return data;
  }
  async getStoragePlayer(): Promise<any> {
    const res = await Storage.get({ key: 'cotg_currentPlayerId' });
    //Get Event From Firebase
    if (res && res.value) {
      const eventElem = await getDoc(doc(this.fb, 'players', res.value));
      const eventData = eventElem.data();
      return eventData;
    }
  }
  async resumeEvent() {
    //Get Event Id from Local Storage
    const eventData = await this.getStorageEvent();
    // const playerData = await this.getStoragePlayer();

    //Check to see if Game is not complete
    if (eventData && eventData.status === 'complete') {
      //Remove this event from storage
      const res = await Storage.remove({ key: 'cotg_currentEventFirebaseId' });
      this.eventInProgress = false;
      return false;
    }
    if( eventData && (eventData.status ==='play' ||  eventData.status ==='planning' || eventData.status ==='pre' )){
      this.eventInProgress = true;
    }

    return this.eventInProgress;
  }
}
